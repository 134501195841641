import { PageProps } from 'gatsby';
import { ThoughtData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import thought from '~/params/thought.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const ThoughtUpdate: FC<EspaceProps & PageProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { thoughtId },
  } = props;

  const model = new ThoughtData({
    espaceId: espace.id,
    params: thought,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form docId={thoughtId} model={model} type="update" />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ThoughtUpdate);
